import Vue from 'vue'
import VueRouter from 'vue-router'
import index from '../views/home/index.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'index',
    component: index,
    redirect: '/index',
    children: [
      {
        path: '/index',
        name: '/index',
        component: resolve => require(['../views/home/index.vue'], resolve),
        meta: { requireAuth: true },
      }]
  },
  {
    path: '/complain',
    name: '/complain',
    component: resolve => require(['../views/complain/index.vue'], resolve),
    meta: { requireAuth: true },
  },
  {
    path: '/extend',
    name: '/extend',
    component: resolve => require(['../views/extend/index.vue'], resolve),
    meta: { requireAuth: true },
  },
  {
    path: '/details',
    name: '/details',
    component: resolve => require(['../views/home/details.vue'], resolve),
    meta: { requireAuth: true },
  },
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
