<template>
  <div>
    <div class="footer">
      <div class="f-box">
        <ul>
          <li>
            <h3>动态资讯</h3>
            <a href="/#/newsDetails?id=4">量化交易实现稳定收益</a>
            <a href="/#/newsDetails?id=5">反洗钱知识宣传——反洗钱信息安全保护</a>
            <a href="/#/newsDetails?id=6">关于我司经营范围变更公告</a>
          </li>

          <li>
            <h3>旗下基金</h3>
            <a href="">创亮风起一号私募证券投资基金</a>
            <a href="">创亮银泽精选债券一期私募证券投资基金</a>
            <a href="">创亮鑫宏优选FOF私募证券投资基金</a>
          </li>


          <li>
            <h3>关于我们</h3>
            <a href="/#/about">公司介绍</a>
            <a href="/#/team">团队介绍</a>
            <a href="/#/tactics">投资策略</a>
            <a href="/#/honor">公司荣誉</a>
          </li>
        </ul>
        <div class="contact">
          <h3>联系我们</h3>
          <p class="phone">电话: 023-86319207</p>
          <p class="address">
            地址: 重庆市南岸区南滨路22号2栋15-13
          </p>
          <p class="address">邮箱: cqclmd@139.com</p>
        </div>
      </div>
    </div>
    <div class="bottom">
      <p>
        版权所有©2024 重庆创亮明德私募证券投资基金管理有限公司
        <a
          style="color: #fff"
          href="https://beian.miit.gov.cn/"
          target="_blank"
        >
          渝公网安备 50010802004208号</a
        >
      </p>
    </div>
  </div>
</template>
      
<script>
export default {
  data() {
    return {
    };
  },
  created() {
  },
  methods: {},
};
</script>
      
<style lang="less" scoped>
.footer {
  width: 100%;
  height: auto;
  background-color: #3c3c3a;
  padding-top: 30px;
  padding-bottom: 50px;
  .f-box {
    width: 1250px;
    margin: auto;
    display: flex;
    ul {
      width: 60%;
      display: flex;
      li {
        width: 100%;
        list-style: none;
        h3 {
          width: 80%;
          height: 70px;
          line-height: 70px;
          font-size: 20px;
          margin-bottom: 10px;
          color: #fff;
          border-bottom: 1px solid #4b4b49;
        }

        a {
          width: 225px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          height: 35px;
          line-height: 45px;
          display: block;
          color: #bbbbbb;
          text-decoration: none;
          font-size: 15px;
        }
      }
    }
    .contact {
      width: 40%;
      h3 {
        width: 100%;
        height: 70px;
        line-height: 70px;
        font-size: 20px;
        margin-bottom: 10px;
        color: #fff;
        border-bottom: 1px solid #4b4b49;
      }
      p {
        height: 35px;
        line-height: 35px;
        display: block;
        color: #bbbbbb;
        text-decoration: none;
        font-size: 15px;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      img {
        width: 100px;
        height: 100px;
        border: 1px solid #f5f6f7;
        margin-top: 10px;
      }
    }
  }
}
.bottom {
    background-color: #2a2a2a;
    padding: 20px 0;
    text-align: center;
    line-height: 20px;
    font-size: 14px;
    color: #858484;
}
</style>