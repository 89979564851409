import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    access_token: sessionStorage.getItem('access_token') ? sessionStorage.getItem('access_token') : '',
    agreement: sessionStorage.getItem('agreement') ? sessionStorage.getItem('agreement') : '',
    userData: sessionStorage.getItem('userData') ? JSON.parse(sessionStorage.getItem('userData')) : {}
  },
  mutations: {
    login(state,data) {
      state.access_token = data
      sessionStorage.setItem("access_token", data); 
    },
    logout(state) {
      sessionStorage.clear()
      // localStorage.removeItem('userData')
      state.access_token = ''
      state.userData = {}
    },
    user(state,data) {
      state.userData = data
      sessionStorage.setItem("userData", JSON.stringify(data)); 
    },
    agreement(state,data) {
      state.agreement = data
      sessionStorage.setItem("agreement", data); 
    }
  },
  actions: {
  },
  modules: {
  }
})
