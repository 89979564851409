import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import axios from 'axios'
import moment from 'moment';
import qs from 'qs'
import 'element-ui/lib/theme-chalk/index.css';
import './theme/index.css'
Vue.prototype.$qs = qs
Vue.prototype.$axios = axios
import * as echarts from 'echarts';
Vue.prototype.$echarts = echarts;
import Vue2Editor from "vue2-editor";

// import VueAwesomeSwiper from 'vue-awesome-swiper'
// import 'swiper/css/swiper.css'
// Vue.use(VueAwesomeSwiper)

// import rem from '@/utils/rem.js'
// import 'lib-flexible/flexible.js'
// Vue.config.productionTip = false
// Vue.use(rem);
Vue.use(Vue2Editor);
Vue.use(ElementUI);
import VScaleScreen from 'v-scale-screen'
Vue.use(VScaleScreen)

import banner from '@/components/banner'
Vue.component('v-banner',banner)

import header from '@/components/header'
Vue.component('v-header',header)

import footer from '@/components/footer'
Vue.component('v-footer',footer)

import connection from '@/components/connection'
Vue.component('v-connection',connection)

Vue.prototype.$formattedDate = function(data,format) {
  return moment(data).format(format);
}

Vue.prototype.$moneyFormat = function(data) {
  if(data.toString().length > 1000000) {
    return data.toFixed(2)
  }
}

Vue.prototype.$to = function(url) {
	router.push(url)
}

Vue.config.productionTip = false


const setHtmlFontSize = () => {
  const htmlDom = document.getElementsByTagName('html')[0];
  let htmlWidth = document.documentElement.clientWidth || document.body.clientWidth;
  if (htmlWidth >= 750) {
    htmlWidth = 750;
  }
  if (htmlWidth <= 320) {
    htmlWidth = 320;
  }
  htmlDom.style.fontSize = `${htmlWidth / 7.5}px`;
};
window.onresize = setHtmlFontSize;
setHtmlFontSize();

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
