import request from '@/utils/request'


export function getType() {
    return request({
        url: '/product/type',
        method: 'get'
    })
}


export function addProduct(data) {
    return request({
        url: '/product/addProduct', 
        method: 'post',
        data
    })
}

export function getList(pageNum,pageSize,title,start,arrive,type,fanwei) {
    return request({
        url: '/product/list?pageNum=' + pageNum + '&pageSize=' + pageSize + '&name=' + title + '&start=' + start + '&arrive=' + arrive + '&type=' + type + '&fanwei=' + fanwei,
        method: 'get'
    })
}

export function getDetails(id) {
    return request({
        url: '/product/details?id=' + id,
        method: 'get'
    })
}


export function saveMake(data) {
    return request({
        url: '/product/saveMake/', 
        method: 'post',
        data
    })
}