<template>
  <div class="content">
    <v-header></v-header>
    <div class="search">
      <!-- <div class="top">
        <p @click="selectIndex = 0" :class="selectIndex == 0 ? 'xz' : ''">关键词搜索</p>
        <p @click="selectIndex = 1" :class="selectIndex == 1 ? 'xz' : ''">地区搜索</p>
      </div> -->
      <div class="list" v-if="index == 0">
        <el-input v-model="title" placeholder="请输入关键词"></el-input>
        <el-button type="primary" @click="getList()">立即搜索</el-button>
      </div>

      <div class="list list2" v-if="index == 1 ">
        <el-input v-model="start" placeholder="始发地"></el-input>
        <p>到</p>
        <el-input v-model="arrive" placeholder="到达地"></el-input>
        <el-button type="primary" @click="getList()">立即搜索</el-button>
      </div>

      <div class="select">
        <p>行业类型：</p>
        <p class="ys"  :class="selectIndex == -1 ? 'f800' : ''" @click="selectIndex = -1;title = '';start = '';arrive = '';type = '';getList()">全部</p>
        <p class="ys" v-for="(list,index) in typeList" :key="index" 
        :class="selectIndex == index ? 'f800' : ''"
        @click="select(list,index)">{{list.title}}</p>
        <!-- <p class="ys" @click="selectIndex = 0">机械制造</p> -->
      </div>

    </div>

    <!-- <div class="select">
      <a href="">全部</a>
    </div> -->

    <div class="item">
      <div class="list" v-for="(list,index) in productList" :key="index">
        <a href="javasciper:;" @click="$to('/details?id=' + list.id)">
          <div class="ls title">
            <p class="iconfont icon-gongsi"></p>
            <span>{{list.name}}</span>
          </div>
          <!-- <div class="imgs" v-if="list.is_top == 1">
              <img src="http://www.xn--114-st1hu08g.com//static/image/20240306/1c98993f7c1ad46de9b6a8fe8138674d.png" style="width:30px;margin-left:20px;margin-top:13px" />
            </div> -->
        </a>
      </div>
    </div>

    <div class="footer" style="font-size: 14px;width: 95%;margin: auto;border-top: 1px solid #f5f3f7;padding-top: 10px;text-align: center;font-weight: 400;">
      Copyright ©2023-2024 德州众搜信息技术有限公司 <a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank">鲁ICP备2023050409号-1</a>
    </div>
  </div>
</template>
      
<script>
import { getList,getType } from '@/api/product'
import header from "@/components/header";
export default {
  components: {
    "v-header": header,
  },
  data() {
    return {
      productList: [],
      typeList: [],
      pageNum: 1,
      pageSize: 100,
      total: 0,
      selectIndex: -1,
      index: 0,
      title: '',
      start: '',
      arrive: '',
      type: ''
    };
  },
  watch: {
    selectIndex(val) {
      if(val == -1) {
        this.index = 0
        // this.start = ''
        // this.arrive = ''
        // this.type = ''
        // this.title = ''
      }
      this.getList()
    }
  },
  created() {
    this.getType()
    this.getList()
  },
  methods: {
    select(list,index) {
      this.title = ''
      this.start = ''
      this.arrive = ''
      this.type = list.title 
      this.selectIndex = index
      
      if(list.title == '物流行业' ) {
        this.index = 1
      } else {
        this.index = 0
      }
      this.getList()
    },
    getType() {
      getType().then(res => {
        this.typeList = res.data
      })
    },
    getList() {
      getList(this.pageNum,this.pageSize,this.title,this.start,this.arrive,this.type,this.title).then(res => {
        this.productList = res.data.list
        this.total = res.data.total
      })
    }
  },
};
</script>
      
<style lang="less" scoped>
@import url("./index.less");
</style>