<template>
  <div>
    <div class="header">
      <div class="boxs">
        <div class="logo">
          <p style="color: #000; font-size: 26px; font-weight: 800">电话 114</p>

          <span style="display: block">阿里云技术支持</span>
        </div>
        <ul>
          <li>
            <a href="/" :style="$route.path == '/index' ? 'color: #ed7d30' : ''"
              >首页</a
            >
          </li>
          <li>
            <a
              href="/#/complain"
              :style="$route.path == '/complain' ? 'color: #ed7d30' : ''"
              >投诉建议</a
            >
          </li>
          <li><a href="/#/extend" :style="$route.path == '/extend' ? 'color: #ed7d30' : ''">我要推广</a></li>
          <li @click="dialogVisible = true"><a>挪车</a></li>

          <li style="cursor: pointer;" @click="appointment"><a>我要预约</a></li>
        </ul>
      </div>
    </div>

    <el-dialog
      :visible.sync="dialogVisible"
    >
      <div>
        <img
          src="@/assets/zc.jpg"
          style="display: block; height:100%; margin: auto; width: 100%"
          alt="电话 114"
        />
      </div>
    </el-dialog>
  </div>
</template>
        
<script>
export default {
  props: {
    background: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
    con: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      dialogVisible: false,
      // agreement: localStorage.getItem('agreement')
    };
  },
  methods: {
    logout() {
      this.$store.commit("logout");
      this.$to("/");
    },
    confirm() {
      this.dialogVisible = false;
      sessionStorage.setItem("isCheck", true);
    },
    appointment() {
      alert('正在开发中！')
    },
    open() {
      alert(1);
    },
  },
};
</script>
        
<style lang="less">
/deep/ .el-dialog__wrapper {
  /deep/ .el-dialog {
    width: 80% !important;
    height: 100px !important; 
  }
}
.header {
  width: 100%;
  height: auto;
  background: #fff;
  /* border-bottom: 1px solid rgba(0, 0, 0, 0.1); */
  display: flex;
  align-items: center;
}

.header .boxs {
  width: 1100px;
  height: 100%;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 20px;
  padding-top: 20px;
}

.header .logo {
  width: 20%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.header .logo span {
  font-size: 15px;
  color: #000;
  opacity: 0.7;
}

.header .logo img {
  width: 80%;
}

.header ul {
  /*width: 80%;*/
  display: flex;
  /*justify-content: flex-end;*/
}

.header ul li {
  width: 100px;
  height: 100%;
  list-style: none;
  /*display: flex;*/
  margin-right: 20px;
  font-size: 19px;
  align-items: center;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 10px;
  font-weight: 500;
}

.header ul li a {
  text-decoration: none;
  color: #000;
  opacity: 1;
}

.content {
  width: 1100px;
  height: auto;
  background: #fff;
  margin: auto;
  padding-top: 20px;
  padding-bottom: 20px;
}

.item {
  width: 95%;
  display: flex;
  margin: auto;
  flex-wrap: wrap;
}

.item .list {
  width: 100%;
  height: 60px;
  line-height: 60px;
  /* border-bottom: 1px solid #f5f6f7; */
}

.item .list a {
  display: block;
  width: 100%;
  height: 100%;
  text-decoration: none;
  color: #000;
  display: flex;
  /*justify-content: space-between;*/
  align-content: center;
}

a .title {
  font-size: 21px;
  font-weight: 800;
}

a .address {
  font-size: 18px;
  font-weight: 400;
}

.list .title {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.list .title p {
  margin-right: 10px;
}

.list .title span {
  margin-right: 10px;
}

.address {
  display: flex;
  align-items: center;
  color: #000;
  opacity: 0.7;
}

.address p {
  margin-right: 10px;
}

.address span {
  font-size: 15px;
}

.radius {
  display: flex;
  align-items: center;
  color: #000;
  opacity: 0.7;
  font-size: 15px;
  margin-top: 5px;
}

.type {
  width: 95%;
  height: 40px;
  margin: auto;
  font-size: 17px;
  /* border-bottom: 1px solid #f5f6f7; */
  margin-bottom: 20px;
  display: flex;
}

.type a {
  margin-right: 15px;
}

@media (max-width: 680px) {
  .header {
    width: 100%;
    ul {
      width: 100%;
      margin: auto;
      display: flex;
      justify-content: space-between;
      li {
        width: 20%;
        margin-right: 0rem;
      }
    }
  }

  .content {
    width: 100%;
  }

  .header .logo {
    width: 100%;
    text-align: center;
  }

  .header .logo p {
    width: 100%;
    text-align: center;
  }

  .search button {
    width: 30%;
  }

  .header ul li a {
    font-size: 0.35rem;
  }

  .select {
    font-size: 30px;
  }
}
</style>