<template>
  <div>
    <div class="data">
      <div class="data-box">
        <p>企业愿景</p>
        <span>成为有长久影响力的</span>
        <span>世界级基金管理公司</span>
        <a href="">立即查看</a>
      </div>
    </div>
  </div>
</template>
        
  <script>
export default {
  data() {
    return {};
  },
  created() {},
  methods: {},
};
</script>
        
  <style lang="less" scoped>
.data {
  width: 100%;
  height: 500px;
  background: url(http://etf.sse.com.cn/imgs/index-navigation-entrance-bg.jpg)
    no-repeat center;
  background-attachment: fixed;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  &-box {
    width: 1250px;
    margin: auto;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    p {
      font-size: 20px;
      color: #fff;
      margin-bottom: 5px;
      font-weight: 500;
    }
    span {
      color: #fff;
      font-size: 30px;
      font-weight: 700;
      display: block;
      letter-spacing: 1px;
    }
    a {
      width: 240px;
      height: 50px;
      line-height: 50px;
      background: none;
      color: #fff;
      border: 1px solid #fff;
      display: block;
      text-align: center;
      text-decoration: none;
      border-radius: 3px;
      font-weight: 700;
      margin-top: 50px;
      transition: 0.3s;
      &:hover {
        background-color: #ED7D30;
        border: 1px solid #ED7D30;
      }
    }
  }
}
</style>