<template>
  <div>
    <div class="banner">
      <h3>{{ title }}</h3>
      <p>{{ info }}</p>
    </div>
  </div>
</template>
      
  <script>
export default {
  props: {
    title: {
      type: String,
      default: ''
    },
    info: {
      type: String,
      default: ''
    }
  },
  data() {
    return {};
  },
  created() {},
  methods: {},
};
</script>
      
<style lang="less" scoped>
.banner {
  width: 100%;
  height: 300px;
  margin: auto;
 
  background-size: 100%;
  background-attachment: fixed;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  h3 {
    font-size: 40px;
    margin-bottom: 10px;
    font-weight: 300;
    color: #fff;
    text-align: center;
  }
  p {
    width: 900px;
    font-size: 16px;
    color: #ed7d30;
    text-align: center;
  }
}
</style>